
const SiteSelectors = {
    siteHierarchy:  state => state.siteReducer.siteHierarchy,
    siteCompounds:  state => state.siteReducer.siteHierarchy?.compounds ?? [],
    isSiteOpenForOrders: state => state.siteReducer.siteHierarchy.isSiteOpenForOrders,
    unavailableCellIds: state => state.siteReducer.unavailableCellIdsBetweenDatesForSite,
    loading:  state => state.siteReducer.loading

}

export default SiteSelectors;
import React, { useEffect, useState } from "react";
import { Formik } from "formik";
import OtpInput from "react-otp-input";

import { useApi } from "hooks/useApi";
import useTranslations from "hooks/useTranslations";
import PageTitle from "components/PageTitle/PageTitle";
import Button from "components/Button/Button";
import "./Migration.scss";
import { useHistory, useLocation } from "react-router-dom";

const OTP = () => {
  const [showErrorMessage, setShowErrorMessage] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [timer, setTimer] = useState(30);
  const [newOtpToken, setNewOtpToken] = useState("");

  const { executeApi, post } = useApi();
  const { rcTranslate } = useTranslations();
  const history = useHistory();
  const location = useLocation();

  const otpToken = location?.state?.otpToken;
  const shovarNumber = location?.state?.shovarNumber;

  const initialValues = {
    otp: "",
  };

  let interval;

  useEffect(() => {
    if (!otpToken || !shovarNumber) {
      history.push("/voucher-number");
    }
  }, [otpToken, shovarNumber, history]);

  useEffect(() => {
    interval = setInterval(() => {
      setTimer((timer) => timer - 1);
    }, 1000);
    if (timer === 0) {
      clearInterval(interval);
    }

    return () => clearInterval(interval);
  }, [timer]);

  const showErrors = (res) => {
    let errorMessage = "",
      result;
    if (res?.message) {
      result = JSON.parse(res?.message);
    }
    if (result?.status && result?.status !== 400) {
      result?.data?.errors?.forEach((element) => {
        errorMessage += " " + element?.message;
      });
    } else {
      errorMessage = res;
    }
    setErrorMessage(errorMessage);
    setShowErrorMessage(true);
  };

  const handleSubmit = async (values, actions) => {
    try {
      const body = {
        otpToken:newOtpToken!==""?newOtpToken:otpToken,
        otp: values?.otp,
        // recaptchaKey: "1saf4sdg5dfh1", in future send recaptchaKey
      };
      const res = await executeApi(post("Shovar/ValidateShovarOtp", body));
      if (res?.isSucceeded) {
        history.push({
          pathname: "/student-details",
          state: {
            shovarNumber,
            realizationToken: res?.content?.realizationToken,
          },
        });
      } else {
        await showErrors(res);
      }
      actions.setSubmitting(false);
    } catch (err) {
      actions.setSubmitting(false);
    }
  };

  const resendOtp = async () => {
    const body = {
      otpToken:newOtpToken!==""?newOtpToken:otpToken,
      // ,recaptchaKey: "1saf4sdg5dfh1",
    };
    const res = await executeApi(post("Shovar/ResendShovarOtp", body));
    if (res?.isSucceeded) {
      console.log(res?.content?.otpToken);
      setNewOtpToken(res?.content?.otpToken);
      setTimer(30);
    } else {
      showErrors(res);
    }
  };
  return (
    <div className="studentDetailsMigration">
      <PageTitle title={rcTranslate("migration.otp.title")} className="title" />
      <div className="otp__formWrapper">
        <Formik
          initialValues={initialValues}
          onSubmit={handleSubmit}
          enableReinitialize={true}
        >
          {({
            values,
            errors,
            touched,
            isSubmitting,
            handleSubmit,
            setFieldTouched,
            setFieldValue,
            setFieldError,
          }) => {
            const handleFieldTouch = (id) => {
              if (!touched[id]) {
                setFieldTouched(id, true);
              }
            };

            const onChange = (id, value) => {
              handleFieldTouch(id);
              setFieldValue(id, value);
            };

            const getFieldError = (fieldId) =>
              errors[fieldId] && touched[fieldId] ? errors[fieldId] : null;

            const onSubmit = (e) => {
              e.preventDefault();
              handleSubmit();
            };

            return (
              <form className="otp__form" onSubmit={onSubmit}>
                <OtpInput
                  numInputs={4}
                  value={values.otp}
                  name="otp"
                  onChange={(otp) => onChange("otp", otp)}
                  className="otp__inputs"
                  shouldAutoFocus={true}
                  isInputNum={true}
                  hasErrored={true}
                  isDisabled={isSubmitting}
                />

                {showErrorMessage && (
                  <p className="migration__errorMessage">{errorMessage}</p>
                )}

                <Button
                  type="submit"
                  title={rcTranslate("button.continue")}
                  disabled={isSubmitting || values?.otp?.length !== 4}
                />
              </form>
            );
          }}
        </Formik>
        {timer !== 0 ? (
          <div className="migration__timer">
            {rcTranslate("migration.otp.timer", [
              { label: "timer", value: timer },
            ])}
          </div>
        ) : (
          <button className="migration__link" role={"link"} onClick={resendOtp}>
            {rcTranslate("migration.otp.sendAgain")}
          </button>
        )}
      </div>
    </div>
  );
};

export default OTP;

import * as React from "react";
import { styled } from "@mui/material/styles";
import ArrowForwardIosSharpIcon from "@mui/icons-material/ArrowForwardIosSharp";
import MuiAccordion from "@mui/material/Accordion";
import MuiAccordionSummary from "@mui/material/AccordionSummary";
import MuiAccordionDetails from "@mui/material/AccordionDetails";
import Typography from "@mui/material/Typography";
import AddIcon from "@mui/icons-material/Add";
import RemoveIcon from "@mui/icons-material/Remove";

const Accordion = styled((props) => (
  <MuiAccordion disableGutters elevation={0} square {...props} />
))(({ theme }) => ({
  border: `1px solid ${theme.palette.divider}`,
  "&:not(:last-child)": {
    borderBottom: 0
  },
  "&:before": {
    display: "none"
  },
  "&.MuiPaper-root": {
    boxShadow: "0px 0px 16px #0033a51a",
    marginBottom: 16,
    borderRadius: 18
  }
}));

const expandIconStyle = {
  fontSize: "1rem",
  backgroundColor: "black",
  color: "white",
  borderRadius: "50%",
  fontWeight: "bold",
  width: 20,
  height: 20
};

const AccordionSummary = styled((props) => (
  <MuiAccordionSummary
    expandIcon={
      props.expanded === 1 ? (
        <RemoveIcon sx={expandIconStyle} />
      ) : (
        <AddIcon sx={expandIconStyle} />
      )
    }
    {...props}
  />
))(({ theme }) => ({
  border: "none",
  direction: "rtl",
  alignItems: "center",
  padding: "20px",
  "& .MuiAccordionSummary-content": {
    marginLeft: theme.spacing(1),
    textAlign: "right",
    margin: 0,
    "& > p": {
      width: "90%",
      fontWeight: 600,
      fontSize: "1.5rem"
    }
  }
}));

const AccordionDetails = styled(MuiAccordionDetails)(({ theme }) => ({
  paddingTop: 0,
  "& .MuiTypography-root": {
    width: "90%",
    textAlign: "right",
    fontSize: "1.125rem",
    direction: "rtl",
    marginLeft: "auto",
    marginRight: 5
  }
}));

const RCAccordion = ({ items = [] }) => {
  const linkRegex = /(https?:\/\/[^\s]+)/g;

  const [expanded, setExpanded] = React.useState(0);

  const handleChange = (panel) => (event, newExpanded) => {
    setExpanded(newExpanded ? panel : false);
  };

  const handleLinkClick = (url) => {
    window.open(url, '_blank');
  };

  const hasUnderline = (text)=> {
    return /@underline@.*@underline@/.test(text);
  }

  const removeUnderline = (text)=> {
    return text.replace(/@underline@/g, '');
  }

  const renderDescription = (description) => {
    if(hasUnderline(description)){
      description = removeUnderline(description);
      return (
        <span style={{ textDecoration: 'underline' }}>
          {description}
        </span>
      );
    }

    const descriptionParts = description.split(linkRegex);
      return descriptionParts.map((part, index) => {
        if (part.match(linkRegex)) {
          return (
            <a
              key={index}
              href={part}
              target="_blank"
              rel="noopener noreferrer"
              style={{ cursor: 'pointer' }}
              onClick={() => handleLinkClick(part)}
            >
              {part}
            </a>
          );
        }
        return part;
      });
  };

  return (
    <div>
      {items?.map(({ title, description }, index) => {
        return (
          <Accordion
            key={index}
            expanded={expanded === index}
            onChange={handleChange(index)}
          >
            <AccordionSummary
              aria-controls="panel1d-content"
              id="panel1d-header"
              expanded={expanded === index ? 1 : 0}
            >
              <Typography
                style={expanded === index ? { color: "#358fb0" } : {}}
              >
                {title}
              </Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Typography>
                {description.split('@linebreak@').map((item, index) => (
                  <React.Fragment key={index}>
                    {renderDescription(item)}
                    <br />
                  </React.Fragment>
                ))}
              </Typography>
            </AccordionDetails>
          </Accordion>
        );
      })}
    </div>
  );
};

export default RCAccordion;

import React from "react";

import useTranslations from "hooks/useTranslations";

import "./button.scss";

const Button = ({
  className = "",
  type = "button",
  color = "torquise",
  title,
  disabled = false,
  onClick = () => {}
}) => {
  const { rcTranslate } = useTranslations();

  const classnames = [
    "button",
    className,
    color,
    disabled ? "button__disabled" : ""
  ].join(" ");

  return (
    <button
      className={classnames}
      type={type}
      disabled={disabled}
      onClick={onClick}
    >
      {rcTranslate(title)}
    </button>
  );
};

export default Button;

import React from "react";
import { Drawer } from "@mui/material";
import { Link } from "react-router-dom";
import Logo from "components/Logo/Logo";
import LogoRavBariah from "assets/images/Logo.png";
import { ReactComponent as CloseIcon } from "assets/images/close.svg";
import Button from "components/Button/Button";
import { useHistory } from "react-router-dom";
import useTranslations from "hooks/useTranslations";
import LogoLockerimRavBariah from "assets/images/logo_lockerim_rav_bariah.png";

import "./SideMenu.scss";

const SideMenu = ({ items, onClose, show }) => {
  const history = useHistory();
  const { rcTranslate } = useTranslations();

  const onClickOrderLocker = () => {
    history.push("/onboarding/landing-page");
    onClose();
  }

  return (
    <Drawer className="sideMenu" anchor={"left"} open={show} onClose={onClose}>
      <div className="header">
        <CloseIcon onClick={onClose} className="header__close-icon" />
        <button className="header__logo-button">
            <img src={LogoLockerimRavBariah} alt="logoRavBariah" className="logoImage"/>
        </button>
      </div>
      <div className="sideMenu__links-wrapper">
        {items.map((item, index) => (
          <Link key={index} className="sideMenu__link" to={item.link} onClick={onClose}>
            {item.title}
          </Link>
        ))}
      </div>
      <div className="order__locker-wrapper">
        <Button className="order__locker-button"
              onClick={onClickOrderLocker}
              title={rcTranslate("button.orderLocker")}
          />
      </div>
      
    </Drawer>
  );
};

export default SideMenu;

const CONSTANTS = {
  GENERAL: {
    LOCAL_STORAGE_DIRECTION_KEY: "lockerim-direction",
    LOCAL_STORAGE_LANGUAGE_KEY: "lockerim-language",
    DIRECTION_RTL: "rtl",
    DIRECTION_LTR: "ltr",
    LANGUAGE_HEBREW: "he",
    LANGUAGE_ENGLISH: "en",
  },
  STEPPER: {
    TOTAL_STEPS: {
      DEFAULT: 8,
      BY_GRADE: 8,
      BY_COMPOUND: 8,
      BY_LOCKER_NUMBER: 8,
      BY_STUDENT: 10, //For future usage
      SECOND_PHASE: 4
    },
    DEFAULT_CHECKED_STEP: ""
  },
  LOCKER_METHODS_ROUTES: [
    "/onboarding/choose-grade",
    "/onboarding/choose-compound",
    "/onboarding/choose-locker-number"
  ],
  PRICING_PERIOD: {
    PRICING_PERIOD_YEAR: 3,
    PRICING_PERIOD_ACADEMIC_YEAR: 4
  },
  SUBSCRIPTIONS_MODELS: {
    PAY_PER_PERIOD: 18001
  },
  CELL_STATUS: {
    Faulty: 8001,
    Blocked: 8002,
    Occupied: 8003,
    Available: 8004,
  },
  SITE_TYPE: {
    AcademicInstitution: 1
  },
  PATTERNS: {
    DIGITS_SEPARATED_BY_HYPEN: /^\d+-\d+$/
  },
  INTERVAL_TRANSACTION_IFRAME_RELOAD_IN_MINUTES: 19,
  LOCKERIM_MOBILE_CLIENT_APP_STORE_LINKS: {
    GOOGLE_PLAY: "https://play.google.com/store/apps/details?id=com.lockerimrb",
    APP_STORE: "https://apps.apple.com/il/app/lockerimrb/id6448728030"
  },
  ROUTE_URLS: {
    TRANSACTION_IFRAME: "/onboarding/transaction-iframe",
    STUDENT_DETAILS: "/onboarding/student-details"
  }
};

export default CONSTANTS;

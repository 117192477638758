export const onlyDigits = (value) => /^[0-9]*$/.test(value);

export const onlyLetters = (value) => /^[a-z A-Zא-ת]+$/.test(value);

export const validateEmail = (email) => {
  const re =
    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return re.test(String(email).toLowerCase());
};

export const checkIsraeliPhone = (num) => {
  return /^05\d([-]{0,1})\d{7}$/.test(num);
};

export const checkIsraeliID = (id) => {
  id = String(id).trim();

  if (id.length > 9 || id.length < 5 || isNaN(parseInt(id))) return false;

  // Pad string with zeros up to 9 digits
  id = id.length < 9 ? ("00000000" + id).slice(-9) : id;

  return (
    Array.from(id, Number).reduce((counter, digit, i) => {
      const step = digit * ((i % 2) + 1);
      return counter + (step > 9 ? step - 9 : step);
    }) %
      10 ===
    0
  );
};
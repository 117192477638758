import { useSelector } from 'react-redux';
import CitiesSelectors from '../../store/cities/citiesSelectors';
import SiteSelectors from '../../store/site/siteSelectors';
import PricingPeriodsSelectors from '../../store/pricingPeriods/pricingPeriodsSelectors';
import OnBoardingProcessSelectors from '../../store/onBoardingProcess/onBoardingProcessSelectors';
import Loader from 'assets/loaders/loader.gif';
import { useEffect, useState } from 'react';

import "./AppLoader.scss";

const AppLoader = () => {

    const [showLoader, setShowLoader] = useState(false);

    const isFetchCitiesLoading = useSelector(CitiesSelectors.loading);
    const isFetchSitesLoading = useSelector(SiteSelectors.loading);
    const isFetchPricingPlansAndPeriodsLoading = useSelector(PricingPeriodsSelectors.loading);
    const isNoValidateRegistrationLoading = useSelector(OnBoardingProcessSelectors.loading);

    useEffect(() => {
        if(isFetchCitiesLoading || isFetchSitesLoading || isFetchPricingPlansAndPeriodsLoading || isNoValidateRegistrationLoading) setShowLoader(true);
        else setShowLoader(false);

    }, [isFetchCitiesLoading, isFetchSitesLoading, isFetchPricingPlansAndPeriodsLoading, isNoValidateRegistrationLoading]);

    return (
        <>
        {showLoader ? 
            <div className="loaderWrapper">
                <img src={Loader} alt="Loading..." />
            </div> : null}
        </>
    );
}

export default AppLoader;
import { createSlice } from '@reduxjs/toolkit';
import { fetchPricing } from './pricingPeriodsActions';


const initialState = {
    sitePricingPlans: null,
    pricingPeriods: null,
    loading: false
  };
  
  export const pricingPeriodsSlice = createSlice({
    name: 'pricingPeriods',
    initialState,
    reducers: {
      setPricingPeriods: (state, action) => {
        state.pricingPeriods = action.payload;
      },
      resetPricingPeriods: (state) => {
        state.pricingPeriods = null;
      },
    },
    extraReducers: (builder) => {
      builder
        .addCase(fetchPricing.pending, (state) => {
          state.loading = true;
        })
        .addCase(fetchPricing.fulfilled, (state, action) => {
          state.loading = false;
          state.sitePricingPlans = action.payload.sitePricingPlans;
          state.pricingPeriods = action.payload.pricingPeriods;
        })
        .addCase(fetchPricing.rejected, (state) => {
          state.loading = false;
        });
    },
  });
  
  export const {
    setPricingPeriods,
    resetPricingPeriods
 } = pricingPeriodsSlice.actions;

  export default pricingPeriodsSlice.reducer;
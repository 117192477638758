import { Suspense } from "react";
import ScrollToTop from "utils/scrollToTop";
import Footer from "components/Footer/Footer";
import Header from "components/Header/Header";
import AppLoader from "components/AppLoader/AppLoader";
import UltimateErrorsModal from "components/UltimateErrorsModal/UltimateErrorsModal";
import Router from "router";

import "./App.scss";

const App = () => {

  return (
    <div className="App">
      <Suspense fallback="loading...">
          <UltimateErrorsModal />
          <AppLoader />
          <Header />
          <ScrollToTop />
          <Router />
          <Footer />
      </Suspense>
    </div>
  );
}

export default App;

const ErrorCodes = {
    // API
    GeneralError: 1,
    RecaptchaNotValid: 17,

    CellNotPaired: 9003,
    CellUnavailable: 9005,

    // Tranzila
    TRANZILA_006: "006",
    TRANZILA_120: "120",
    TRANZILA_GENERIC: "GENERIC",
    TRANZILA_NO_CHARGE_WAS_MADE: "NO_CHARGE_WAS_MADE",
    NoAvailableCellsFound: "NO_AVAILABLE_CELLS_FOUND",
    SiteIsNotOpenForOrders: "SITE_IS_NOT_OPEN_FOR_ORDERS"
};

export default ErrorCodes;
export const ErrorPriorityEnum = {
    HIGH: 1,
    MEDIUM: 2,
    LOW: 3
};

export const ErrorTypeEnum = {
    SERVER: 'SERVER',
    APP: 'APP'
};

const ActionsEnum = {
    PUSH_ERROR: 'PUSH_ERROR',
    POP_ERROR: 'POP_ERROR',
    CLEAR_ALL_ERRORS: 'CLEAR_ALL_ERRORS'
}

export const ErrorActionsNames = {
    [ActionsEnum.PUSH_ERROR]: ActionsEnum.PUSH_ERROR,
    [ActionsEnum.POP_ERROR]: ActionsEnum.POP_ERROR,
    [ActionsEnum.CLEAR_ALL_ERRORS]: ActionsEnum.CLEAR_ALL_ERRORS
}

const Actions = {
    [ActionsEnum.PUSH_ERROR]: (error) => ({
        type: ActionsEnum.PUSH_ERROR,
        payload: error
    }),
    [ActionsEnum.POP_ERROR]: () => ({
        type: ActionsEnum.POP_ERROR
    }),
    [ActionsEnum.CLEAR_ALL_ERRORS]: () => ({
        type: ActionsEnum.CLEAR_ALL_ERRORS
    })
}

const pushError = (error) => (dispatch) => {
    dispatch(Actions[ActionsEnum.PUSH_ERROR](error));
}

const popError = () => (dispatch) => {
    dispatch(Actions[ActionsEnum.POP_ERROR]());
}

const clearAllErrors = () => (dispatch) => {
    dispatch(Actions[ActionsEnum.CLEAR_ALL_ERRORS]());
}

export const ErrorActions = {
    pushError,
    popError,
    clearAllErrors
}
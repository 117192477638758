import CONSTANTS from "utils/constants";

export const phoneWithDash = (phone) => {
  if (!phone) return "";

  phone = phone.replace("+9720", "0");
  phone = phone.replace("+972", "0");
  phone = phone.split("");
  phone.splice(3, 0, "-");
  phone = phone.join("");
  return phone;
};

export const getErrorsMsg = (err) => {
  let errorMessage = "";
  const result = JSON.parse(err?.message);
  if (err?.response?.status !== 400) {
    result?.forEach((element) => {
      errorMessage += " " + element?.message;
    });
  } else {
    errorMessage = err;
  }
  return errorMessage
};

export const convertStringToObject  = (str) => {
  const keyValuePairs = str.split('&');
  const obj = keyValuePairs.reduce((acc, pair) => {
    const [key, value] = pair.split('=');
    acc[key] = value;
    return acc;
  }, {});
  
  return obj;
};

export const getIntervalTransactionIframeReloadInSeconds = () => {
  return CONSTANTS.INTERVAL_TRANSACTION_IFRAME_RELOAD_IN_MINUTES * 60 * 1000;
};
import React, { useMemo } from "react";
import { useLocation } from "react-router-dom";

import useTranslations from "hooks/useTranslations";
import BackButton from "components/BackButton/BackButton";

import "./Wizard.scss";

const Wizard = ({ children }) => {
  const { direction, oppositeDirection } = useTranslations();
  const location = useLocation();

  const locationWithoutHeader = useMemo(() => ["/onboarding/landing-page"], []);

  const showHeader = useMemo(
    () => !locationWithoutHeader.includes(location?.pathname),
    [location?.pathname, locationWithoutHeader]
  );

  if (!location?.pathname.includes("/onboarding")) {
    return (
      <div className="wizard__empty">
        {children}
      </div>
    )
  }

  return (
    <div className="wizard" style={{ direction }}>
      {showHeader && (
        <div className="wizard__header">
          <div className="wizard__header-empty" />
          <BackButton />
        </div>
      )}

      <div className="wizrd__content">{children}</div>
    </div>
  );
};

export default Wizard;

import { createSlice } from '@reduxjs/toolkit';
import { fetchSiteHierarchy, fetchUnavailableCellIdsBetweenDatesForSite } from './siteActions';

const initialState = {
    siteHierarchy: null,
    unavailableCellIdsBetweenDatesForSite: null,
    loading: false
  };
  
  export const siteHierarchySlice = createSlice({
    name: 'siteHierarchy',
    initialState,
    reducers: {
      setSiteHierarchy: (state, action) => {
        state.siteHierarchy = action.payload;
      },
      removeSiteHierarchy: (state) => {
        state.siteHierarchy = initialState.siteHierarchy;
      },
      setUnavailableCellIdsBetweenDatesForSite: (state, action) => {
        state.unavailableCellIdsBetweenDatesForSite = action.payload;
      },
      removeUnavailableCellIdsBetweenDatesForSite: (state) => {
        state.unavailableCellIdsBetweenDatesForSite = initialState.unavailableCellIdsBetweenDatesForSite;
      }
    },
    extraReducers: (builder) => {
      builder
        .addCase(fetchSiteHierarchy.pending, (state) => {
          state.loading = true;
        })
        .addCase(fetchSiteHierarchy.fulfilled, (state, action) => {
          state.loading = false;
          state.siteHierarchy = action.payload;
        })
        .addCase(fetchSiteHierarchy.rejected, (state) => {
          state.loading = false;
        });
      builder
        .addCase(fetchUnavailableCellIdsBetweenDatesForSite.pending, (state) => {
          state.loading = true;
        })
        .addCase(fetchUnavailableCellIdsBetweenDatesForSite.fulfilled, (state, action) => {
          state.loading = false;
          state.unavailableCellIdsBetweenDatesForSite = action.payload;
        })
        .addCase(fetchUnavailableCellIdsBetweenDatesForSite.rejected, (state) => {
          state.loading = false;
      });  
    },
  });


  export const { 
    setSiteHierarchy,
    setUnavailableCellIdsBetweenDatesForSite
 } = siteHierarchySlice.actions;
  
  
  export default siteHierarchySlice.reducer;
import { useEffect, useState } from "react";

export const useScreen = () => {
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 425);
  const [isTablet, setIsTablet] = useState(window.innerWidth <= 768);
  const [isDesktop, setIsDesktop] = useState(window.innerWidth > 1024);

  useEffect(() => {
    window.addEventListener("resize", () => {
      setIsMobile(window.innerWidth <= 425);
      setIsTablet(window.innerWidth <= 768);
      setIsDesktop(window.innerWidth > 1024);
    });
  }, []);

  return { isMobile, isTablet, isDesktop };
};

import React from "react";
import { useHistory } from "react-router-dom";
import { ReactComponent as BackButtonLogo } from "assets/images/arrow.svg";

import "./BackButton.scss";
import CONSTANTS from "utils/constants";

const BackButton = () => {
  const history = useHistory();

  const goBack = () => {
    const pathName = getRedirectSpecificPage();
    if (pathName) {
      history.go(-1);
    } else {
      history.goBack();
    }
  };

  const getRedirectSpecificPage = () => {
    if(history.location.pathname === CONSTANTS.ROUTE_URLS.TRANSACTION_IFRAME){
      return CONSTANTS.ROUTE_URLS.STUDENT_DETAILS;
    }

    return null;
  };

  return (
    <div className="backButton">
      <div className="backButton__text" onClick={goBack}>
        חזור שלב אחורה
      </div>

      <BackButtonLogo className="backButton__icon" onClick={goBack} />
    </div>
  );
};

export default BackButton;

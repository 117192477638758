import axios from "axios";

const client = axios.create({
  baseURL: process.env.REACT_APP_API_URL
});

const get = (url) => ({
  url,
  method: "get"
});

const getWithAuth = (url, token) => ({
  url,
  method: "get",
  headers: {
    Authorization: token
  }
});

const post = (url, data) => ({ url, data, method: "post" });

const postWithAuth = (url, data, token) => ({
  url,
  data,
  method: "post",
  headers: {
    Authorization: token
  }
});

export { client, get, getWithAuth, post, postWithAuth };

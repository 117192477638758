import { createAsyncThunk } from "@reduxjs/toolkit";
import store from '../store';
import { ApiService } from "services";

export const fetchSiteHierarchy = createAsyncThunk('siteHierarchy/fetchSiteHierarchy', async () => {
    const siteHierarchyFromReducer = store.getState().siteReducer.siteHierarchy;
    
    const siteId = store.getState().onBoardingProcessReducer.siteId;

    if(siteHierarchyFromReducer && siteHierarchyFromReducer.siteId === siteId) return siteHierarchyFromReducer;
    
    try{
      const response = await ApiService.get(`Site/GetSiteHierarchy/${siteId}`);
      let siteHierarchy = response?.data?.content;
      siteHierarchy.siteId = siteId;
      return siteHierarchy;
    }catch(ex){
      console.error(ex);
      return null;
    }
  });

export const fetchUnavailableCellIdsBetweenDatesForSite = createAsyncThunk('siteHierarchy/fetchUnavailableCellIdsBetweenDatesForSite', async () => {
    const siteId = store.getState().onBoardingProcessReducer.siteId;
    
    const startDate = new Date().toISOString();
    const endDate = startDate;

    try{
      const response = await ApiService.get(`Order/GetUnavailableCellIdsBetweenDatesForSite?siteId=${siteId}&startDate=${startDate}&endDate=${endDate}`);
      let unavailableCellIdsBetweenDatesForSite = response?.data?.content;
      unavailableCellIdsBetweenDatesForSite.siteId = siteId;
    
      return unavailableCellIdsBetweenDatesForSite;
    }catch(ex){
      console.error(ex);
      return null;
    }
});
import { createSlice } from '@reduxjs/toolkit';
import { noValidateRegistration, webOnboardingOrder, getAnonymousCardIframe, packageDeliveryConfirmation } from './onBoardingProcessActions';

const initialState = {
    cityId: null,
    cityNameHe: null,
    siteId: null,
    siteNameHe: null,
    compound: null,
    closet: null,
    cell: null,
    pricingPlan: null,
    gradeId: null,
    userDetails: {
      firstName: null,
      lastName: null,
      phoneNumber: null,
      email: null,
      joinMailingList: null
    },
    tranzilaResponse: null,
    order: {
      orderId: null,
      startDate: null,
      endDate: null
    },
    loading: false
  };
  
  const onBoardingProcessSlice = createSlice({
    name: 'onBoardingProcess',
    initialState,
    reducers: {
      resetState: (state) => {
        Object.keys(state).forEach((key) => {
          state[key] = initialState[key];
        });
      },
      setCityId: (state, action) => {
        state.cityId = action.payload;
      },
      resetCityId: (state) => {
        state.cityId = initialState.cityId;
      },
      setCityName: (state, action) => {
        state.cityNameHe = action.payload;
      },
      resetCityName: (state) => {
        state.cityNameHe = initialState.cityNameHe;
      },
      setSiteId: (state, action) => {
        state.siteId = action.payload;
      },
      resetSiteId: (state) => {
        state.siteId = initialState.siteId;
      },
      setSiteName: (state, action) => {
        state.siteNameHe = action.payload;
      },
      resetSiteName: (state) => {
        state.siteNameHe = initialState.siteNameHe;
      },
      setCompound: (state, action) => {
        state.compound = action.payload;
      },
      resetCompound: (state) => {
        state.compound = initialState.compound;
      },
      setCloset: (state, action) => {
        state.closet = action.payload;
      },
      resetCloset: (state) => {
        state.closet = initialState.closet;
      },
      setCell: (state, action) => {
        state.cell = action.payload;
      },
      resetCell: (state) => {
        state.cell = initialState.cell;
      },
      setIsCellUnavailable: (state, action) => {
        state.cell = {
          ...state.cell,
          isCellUnavailable: action.payload
        };
      },
      setPricingPlan: (state, action) => {
        state.pricingPlan = action.payload;
      },
      resetPricingPlan: (state) => {
        state.pricingPlan = initialState.pricingPlan;
      },
      setGradeId: (state, action) => {
        state.gradeId = action.payload;
      },
      resetGradeId: (state) => {
        state.gradeId = initialState.gradeId;
      },
      setUserDetails: (state, action) => {
        state.userDetails = action.payload;
      },
      resetUserDetails: (state) => {
        state.userDetails = initialState.userDetails;
      },
      setTranzilaResponse: (state, action) => {
        state.tranzilaResponse = action.payload;
      },
      resetTranzilaResponse: (state) => {
        state.tranzilaResponse = initialState.tranzilaResponse;
      },
      setOrder: (state, action) => {
        state.order = action.payload;
      },
      resetOrder: (state) => {
        state.order = initialState.order;
      }
    },
    extraReducers: (builder) => {
      builder
        .addCase(noValidateRegistration.pending, (state) => {
          state.loading = true;
        })
        .addCase(noValidateRegistration.fulfilled, (state, action) => {
          state.loading = false;
        })
        .addCase(noValidateRegistration.rejected, (state) => {
          state.loading = false;
        });

        builder
        .addCase(getAnonymousCardIframe.pending, (state) => {
          state.loading = true;
        })
        .addCase(getAnonymousCardIframe.fulfilled, (state) => {
          state.loading = false;
        })
        .addCase(getAnonymousCardIframe.rejected, (state) => {
          state.loading = false;
        });

        builder
        .addCase(webOnboardingOrder.pending, (state) => {
          state.loading = true;
        })
        .addCase(webOnboardingOrder.fulfilled, (state, action) => {
          state.loading = false;
        })
        .addCase(webOnboardingOrder.rejected, (state) => {
          state.loading = false;
        })

        .addCase(packageDeliveryConfirmation.pending, (state) => {
          state.loading = true;
        })
        .addCase(packageDeliveryConfirmation.fulfilled, (state, action) => {
          state.loading = false;
        })
        .addCase(packageDeliveryConfirmation.rejected, (state) => {
          state.loading = false;
        });
    },
  });

  export const {
    resetState,
    setCityId,
    resetCityId,
    setCityName,
    resetCityName,
    setSiteId,
    resetSiteId,
    setSiteName,
    resetSiteName,
    setCompound,
    resetCompound,
    setCloset,
    resetCloset,
    setCell,
    resetCell,
    setIsCellUnavailable,
    setPricingPlan,
    resetPricingPlan,
    setGradeId,
    resetGradeId,
    setUserDetails,
    resetUserDetails,
    setTranzilaResponse,
    resetTranzilaResponse,
    setOrder,
    resetOrder
 } = onBoardingProcessSlice.actions;
  
  export default onBoardingProcessSlice.reducer;
import React from "react";

import useTranslations from "hooks/useTranslations";
import PageTitle from "components/PageTitle/PageTitle";
import RCAccordion from "components/Accordion/Accordion";

import "./Faq.scss";

const Faq = () => {
  const { rcTranslate } = useTranslations();

  const items = () => {
    let faqItems = [];

    for (let i = 1; i <= 21; i++) {
      faqItems.push({
        title: rcTranslate(`faq.items.${i}.title`),
        description: rcTranslate(`faq.items.${i}.description`)
      });
    }

    return faqItems;
  };

  return (
    <div className="faq">
      <PageTitle
          className="faq__title"
          title={rcTranslate("faq.title")}
        />

        <div className="faq__subtitle">
          {rcTranslate("faq.subtitle")}
        </div>
      
      <RCAccordion items={items()} />
    </div>
  );
};

export default Faq;


export const useRecaptcha = () => {

    const id = "recaptcha-key";
    const recaptchaURL = `${process.env.REACT_APP_RECAPTCHA_URL}?render=${process.env.REACT_APP_RECAPTCHA_SITE_KEY}`;
    
    const getRecaptchaKey = (callback) => {
        const isScriptExist = document.getElementById(id);
      
        if (!isScriptExist) {
          const script = document.createElement("script");
          script.type = "text/javascript";
          script.src = recaptchaURL;
          script.id = id;
          script.onload = function () {
            if (callback) callback();
          };
          document.body.appendChild(script);
        }
        if (isScriptExist && callback) callback();
      };

    const getRecaptchaSiteKey = () => {
      return process.env.REACT_APP_RECAPTCHA_SITE_KEY;
    }


return {
    getRecaptchaKey,
    getRecaptchaSiteKey
  };
};

import { object, array, boolean, string, number, mixed } from "yup";

import {
  checkIsraeliPhone,
  checkIsraeliID,
  onlyDigits
} from "utils/validation";

export const studentDetailsLinkValidation = object().shape({
  firstName: string().nullable().required("form.errors.required"),
  lastName: string().nullable().required("form.errors.required"),
  phoneNumber: string()
    .nullable()
    .test("check phone number", "form.errors.phoneNumber", (value) => {
      return checkIsraeliPhone(value);
    })
    .required("form.errors.required"),
  identificationNumber: string()
    .nullable()
    .test(
      "check identification number",
      "form.errors.identificationNumber",
      (value) => {
        return checkIsraeliID(value);
      }
    )
    .required("form.errors.required"),
  school: string().nullable().required("form.errors.required"),
  grade: string().nullable().required("form.errors.required"),
  gradeNumber: number()
    .nullable()
    .test("only digits", "form.errors.required", (value) => {
      return onlyDigits(value);
    })
    .required("form.errors.required")
});

import { createSlice } from '@reduxjs/toolkit';
import { fetchCities } from './citiesActions';


const initialState = {
    cities: null,
    loading: false
  };
  
  export const citiesSlice = createSlice({
    name: 'cities',
    initialState,
    reducers: {},
    extraReducers: (builder) => {
      builder
        .addCase(fetchCities.pending, (state) => {
          state.loading = true;
        })
        .addCase(fetchCities.fulfilled, (state, action) => {
          state.loading = false;
          state.cities = action.payload;
        })
        .addCase(fetchCities.rejected, (state) => {
          state.loading = false;
        });
    },
  });
  
  
  export default citiesSlice.reducer;
import citiesReducer  from './cities/citiesReducer';
import siteReducer  from './site/siteReducer';
import pricingPeriodsReducer  from './pricingPeriods/pricingPeriodsReducer';
import onBoardingProcessReducer  from './onBoardingProcess/onBoardingProcessReducer';
import errorsReducer  from './errors/errorsReducer';
import { combineReducers } from 'redux';

const rootReducer = combineReducers({
  citiesReducer,
  siteReducer,
  pricingPeriodsReducer,
  onBoardingProcessReducer,
  errorsReducer
});

export default rootReducer;
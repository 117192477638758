import axios from "axios";
import store from "../store/store";
import {
  ErrorActions,
  ErrorPriorityEnum,
  ErrorTypeEnum,
} from "store/errors/errorsActions";
import ErrorCodes from "utils/constants/errorCodes";
import { useRecaptcha } from "hooks/useRecaptcha";

class Api {
  axiosInstance;

  constructor() {
    this.axiosInstance = axios.create({
      baseURL: process.env.REACT_APP_API_URL,
      timeout: 1 * 60 * 1000,
    });
    this.responseInteceptor();
  }

  get = (url) => this.axiosInstance.get(url);

  post = (url, data) => this.axiosInstance.post(url, data);

  put = (url, data) => this.axiosInstance.put(url, data);

  responseInteceptor = () => {
    this.axiosInstance.interceptors.response.use(
      async (value) => {
        if (!value?.data?.isSucceeded) {
          const errorMessage = value?.data?.errors[0]?.message || null;
          const errorCode =
            value?.data?.errors[0]?.code || ErrorCodes.GeneralError;

          store.dispatch(
            ErrorActions.pushError({
              code: errorCode,
              message: errorMessage,
              priority: ErrorPriorityEnum.HIGH,
              type: ErrorTypeEnum.SERVER,
            })
          );
        }
        return value;
      },
      async (error) => {
        store.dispatch(
          ErrorActions.pushError({
            code: ErrorCodes.GeneralError,
            priority: ErrorPriorityEnum.HIGH,
            type: ErrorTypeEnum.SERVER,
          })
        );
        throw Error(error);
      }
    );
  };

  apiUsingRecaptcha = async (serviceCallback, request) => {
    return new Promise(async (resolve, reject) => {
      try {
        const { getRecaptchaKey, getRecaptchaSiteKey } = useRecaptcha();
        getRecaptchaKey(() => {
          window.grecaptcha.ready(async () => {
            try {
              const responseToken = await window.grecaptcha.execute(
                getRecaptchaSiteKey(),
                { action: "submit" }
              );
              request.recaptchaKey = responseToken;
              const response = await serviceCallback(request);
              resolve(response);
            } catch (error) {
              console.error(error);
              reject(new Error("Error during API request"));
            }
          });
        });
      } catch (error) {
        console.error(error);
        reject(new Error("Error get recaptcha"));
      }
    });
  };
}

export const ApiService = new Api();

import { createAsyncThunk } from "@reduxjs/toolkit";
import { ApiService } from "services";
import store from '../store';
import CONSTANTS from '../../utils/constants/index';

export const fetchPricing = createAsyncThunk('pricingPeriods/fetchPricing', async () => {
  try {
    const siteId = store.getState().onBoardingProcessReducer.siteId;
    const getSitePricingPlansPromise = ApiService.get(`PricingPlan/GetSitePricingPlans/${siteId}`);
    const getPricingPeriodsPromise = ApiService.get(`PricingPlan/GetPricingPeriods`);

    const [getSitePricingPlansResponse, getPricingPeriodsResponse] = await Promise.all([getSitePricingPlansPromise, getPricingPeriodsPromise]);
    if(getSitePricingPlansResponse?.data?.isSucceeded && getPricingPeriodsResponse?.data?.isSucceeded){
        const pricingPlans = getSitePricingPlansResponse.data.content.pricingPlans;
        const payPerPeriodPricingPlans = pricingPlans.filter(pricingPlan => pricingPlan.subscriptionModel === CONSTANTS.SUBSCRIPTIONS_MODELS.PAY_PER_PERIOD);

        const pricingPeriods = getPricingPeriodsResponse.data.content.pricingPeriods;
        const peyPerPeriodPricingPeriods = pricingPeriods.filter(p => p.payPerPeriod);

        return {
            sitePricingPlans: payPerPeriodPricingPlans,
            pricingPeriods: peyPerPeriodPricingPeriods
        };
    }
  } catch (error) {
    console.error(error);
    throw new Error('Error fetching pricing plan and sitePricingPeriods');
  }
});
import { useState, useEffect, useCallback, useMemo } from "react";
import { useLocation } from "react-router-dom";
import CONSTANTS from "utils/constants";

export const useStorage = () => {

  const [localStorageData, setLocalStorageData] = useState(
    localStorage.getItem("lockerim-onboarding")
      ? JSON.parse(localStorage.getItem("lockerim-onboarding"))
      : null
  );

  const getAppDirection = () => {
    return localStorage.getItem(CONSTANTS.GENERAL.LOCAL_STORAGE_DIRECTION_KEY) 
    ? localStorage.getItem(CONSTANTS.GENERAL.LOCAL_STORAGE_DIRECTION_KEY) :
       CONSTANTS.GENERAL.DIRECTION_RTL
  }

  const getOppositeAppDirection = () => {
    const direction = getAppDirection();
    return direction === CONSTANTS.GENERAL.DIRECTION_RTL 
      ? CONSTANTS.GENERAL.DIRECTION_RTL :
        CONSTANTS.GENERAL.DIRECTION_LTR;
  }

  const getAppLanguage = () => {
    return localStorage.getItem(CONSTANTS.GENERAL.LOCAL_STORAGE_LANGUAGE_KEY) 
    ? localStorage.getItem(CONSTANTS.GENERAL.LOCAL_STORAGE_LANGUAGE_KEY) :
       CONSTANTS.GENERAL.LANGUAGE_HEBREW;
  }

  const initialData = useMemo(() => ({ appLanguage: "he" }), []);

  const routeHistory = useMemo(
    () => localStorageData?.routeHistory ?? [],
    [localStorageData?.routeHistory]
  );

  const location = useLocation();

  useEffect(() => {
    if (localStorageData === null) {
      setLocalStorageData(initialData);
    }
  }, [initialData, localStorageData]);

  const saveToLocalStorage = useCallback(
    (data) => {
      const updatedData = { ...localStorageData, ...data };

      setLocalStorageData(updatedData);
      window.dispatchEvent(new Event("storage"));
    },
    [localStorageData]
  );

  const removeLocalStorageData = useCallback(() => {
    setLocalStorageData(initialData);
    window.dispatchEvent(new Event("storage"));
  }, [initialData]);

  useEffect(() => {
    let storageListener = () => {};

    storageListener = window.addEventListener("storage", (_event) => {
      let updatedLocalStorageData = localStorage.getItem("lockerim-onboarding");

      if (updatedLocalStorageData) {
        updatedLocalStorageData = JSON.parse(updatedLocalStorageData);

        setLocalStorageData(updatedLocalStorageData);
      }
    });

    return () => {
      window.removeEventListener("storage", storageListener);
    };
  }, []);

  useEffect(() => {
    if (
      location?.pathname === "/" ||
      location?.pathname === "/onboarding/landing-page" ||
      routeHistory.includes(location?.pathname)
    )
      return;

    let updateTimeout;

    let newRouteHistory = [...routeHistory];

    let foundRouteIndexToRemoveFromHistory = null;

    for (const [index, route] of routeHistory.entries()) {
      if (CONSTANTS.LOCKER_METHODS_ROUTES.includes(route)) {
        foundRouteIndexToRemoveFromHistory = index;
        break;
      }
    }

    if (foundRouteIndexToRemoveFromHistory) {
      newRouteHistory.splice(
        foundRouteIndexToRemoveFromHistory,
        1,
        location?.pathname
      );
    } else {
      newRouteHistory = [...routeHistory, location?.pathname];
    }

    updateTimeout = setTimeout(() => {
      saveToLocalStorage({
        routeHistory: newRouteHistory
      });
    }, 150);

    return () => {
      clearTimeout(updateTimeout);
    };
  }, [location?.pathname, routeHistory, saveToLocalStorage]);

  return {
    localStorageData,
    saveToLocalStorage,
    removeLocalStorageData,
    getAppDirection,
    getOppositeAppDirection,
    getAppLanguage
  };
};

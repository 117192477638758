import useTranslations from "hooks/useTranslations";
import React from "react";

import "./PageTitle.scss";

const PageTitle = ({ title = "", style = {}, className = "", inputProps = null, parameters = [], subTitle = null }) => {
  const { rcTranslate } = useTranslations();

  return (
    <div className={`pageTitle ${className}  `} style={style}>
      {rcTranslate(title, parameters)}
      {subTitle &&
        <h3 className="subTitle">{subTitle}</h3>
      }
      {inputProps ? <div className="inputPropsClass">{inputProps}</div> : null}
    </div>
  );
};

export default PageTitle;

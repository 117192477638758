import { createAsyncThunk } from "@reduxjs/toolkit";
import { ApiService } from "services";

export const fetchCities = createAsyncThunk('cities/fetchCities', async () => {
  try {
    const response = await ApiService.get('City/GetCitiesSites');
    return response.data.content.cities;
  } catch (error) {
    throw new Error('Error fetching cities');
  }
});
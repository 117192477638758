import { object, string } from "yup";

import { onlyDigits } from "utils/validation";

export const voucherValidation = object().shape({
  voucherNumber: string()
    .required("form.errors.required")
    .test("only digits", "form.errors.required", (value) => {
      return onlyDigits(value);
    }),
});

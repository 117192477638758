import { ErrorActionsNames } from "./errorsActions";

const initialState = {
    errors: [],
    permissionErrors: [],
}

const errorReducer = (state = initialState, action) => {
    switch (action.type) {
      case ErrorActionsNames.PUSH_ERROR:
        const alreadyExist = state.errors.find(err => err.code === action.payload.code);
        if (!alreadyExist) {
          const newErrors = [...state.errors, action.payload];
          newErrors.sort((a, b) => a.priority - b.priority);
          return { ...state, errors: newErrors };
        }
        return state;
        
      case ErrorActionsNames.POP_ERROR:
        const poppedErrors = state.errors.slice(1);
        return { ...state, errors: poppedErrors };
  
      case ErrorActionsNames.CLEAR_ALL_ERRORS:
        return { ...state, errors: [] };
  
      default:
        return state;
    }
  };

export default errorReducer;
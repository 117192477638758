import React, {useState, useEffect} from "react";
import MUIDialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import Button from "../Button/Button";
import generalError from "assets/images/generalError.png";
import errorsSelectors from '../../store/errors/errorsSelectors';
import { useDispatch, useSelector } from 'react-redux';
import { ErrorActions } from '../../store/errors/errorsActions';
import useTranslations from "hooks/useTranslations";
import { ReactComponent as CreditCard } from "assets/images/creditCard.svg";
import { ReactComponent as HotelBuilding } from "assets/images/noun-hotel-building.svg";
import { useHistory } from "react-router-dom";
import { ReactComponent as ClosetImage } from "assets/images/Closet.svg";
import ErrorCodes from "utils/constants/errorCodes";

import "./UltimateErrorsModal.scss";

export default function UltimateErrorsModal() {
    const dispatch = useDispatch();
    const { rcTranslate } = useTranslations();
    const history = useHistory();

    const shouldShowErrorModal = useSelector(errorsSelectors.isErrorsExist);
    const nextError = useSelector(errorsSelectors.getNextError);

    const [currentError, setCurrentError] = useState();

    const [headerImage, setHeaderImage] = useState();
    const [headerTitle, setHeaderTitle] = useState();
    const [headerSubTitle, setHeaderSubTitle] = useState();
    const [buttonText, setButtonText] = useState();

    useEffect(() => {
        if(nextError?.code) setCurrentError(nextError);
      }, [nextError?.code])
    
      useEffect(() => {
        setHeaderImageBasedOnErrorCode(currentError?.code);
        setHeaderTitleTextBasedOnErrorCode(currentError?.code, currentError?.message);
        setHeaderSubTitleTextBasedOnErrorCode(currentError?.code, currentError?.message);
        setMainButtonTextBasedOnErrorCode(currentError?.code, currentError?.message);
      }, [currentError?.code]);

      const setHeaderImageBasedOnErrorCode = (errorCode, errorMessage) =>  {
        switch (errorCode?.toString()) {
          case ErrorCodes.TRANZILA_GENERIC: 
          case ErrorCodes.TRANZILA_NO_CHARGE_WAS_MADE:
            setHeaderImage(<CreditCard/>);
            break;
          case ErrorCodes.CellUnavailable.toString():
            setHeaderImage(<ClosetImage/>);
            break;
          case ErrorCodes.NoAvailableCellsFound.toString():
            setHeaderImage(<ClosetImage/>);
              break;
          case ErrorCodes.SiteIsNotOpenForOrders.toString():
            setHeaderImage(<HotelBuilding/>);
              break;
          default:
            setHeaderImage(<img src={generalError} />);
            break;
        }
      }
    
      const setHeaderTitleTextBasedOnErrorCode = (errorCode, errorMessage) => {
        switch (errorCode?.toString()) {
          case ErrorCodes.TRANZILA_GENERIC:
          case ErrorCodes.TRANZILA_NO_CHARGE_WAS_MADE:
            setHeaderTitle(rcTranslate("ultimateErrorModal.tranzila.title"));
            break;
          case ErrorCodes.CellUnavailable.toString():
            setHeaderTitle(rcTranslate("ultimateErrorModal.unAvailableCell.title"));
            break;
          case ErrorCodes.NoAvailableCellsFound.toString():
            setHeaderTitle(rcTranslate("ultimateErrorModal.noAvailableCellsFound.title"));
              break;
          case ErrorCodes.SiteIsNotOpenForOrders.toString():
            setHeaderTitle(rcTranslate("ultimateErrorModal.siteIsNotOpenForOrders.title"));
              break;
          default:
            setHeaderTitle(errorMessage ? errorMessage : rcTranslate("ultimateErrorModal.title"));
            break;
        }
      }

      const setHeaderSubTitleTextBasedOnErrorCode = (errorCode) => {
        switch (errorCode?.toString()) {
          case ErrorCodes.TRANZILA_GENERIC:
            setHeaderSubTitle(rcTranslate("ultimateErrorModal.tranzila.subTitleGeneric"));
            break;
          case ErrorCodes.TRANZILA_NO_CHARGE_WAS_MADE:
            setHeaderSubTitle(rcTranslate("ultimateErrorModal.tranzila.subTitleNoChargeWasMade"));
            break;
          case ErrorCodes.CellUnavailable.toString():
            setHeaderSubTitle(rcTranslate("ultimateErrorModal.unAvailableCell.subTitle"));
              break;
          case ErrorCodes.NoAvailableCellsFound.toString():
            setHeaderSubTitle(rcTranslate("ultimateErrorModal.noAvailableCellsFound.subTitle"));
              break;
          case ErrorCodes.SiteIsNotOpenForOrders.toString():
            setHeaderSubTitle(rcTranslate("ultimateErrorModal.siteIsNotOpenForOrders.subTitle"));
              break;
          default:
            return null;
        }
      }

      const setMainButtonTextBasedOnErrorCode = (errorCode) => {  
        switch (errorCode?.toString()) {
          case ErrorCodes.TRANZILA_GENERIC: 
          case ErrorCodes.TRANZILA_NO_CHARGE_WAS_MADE:
            setButtonText(rcTranslate("ultimateErrorModal.tranzila.buttonText"));
            break;
          case ErrorCodes.CellUnavailable.toString():
              setButtonText(rcTranslate("ultimateErrorModal.unAvailableCell.buttonText"));
              break;
          case ErrorCodes.NoAvailableCellsFound.toString():
            setButtonText(rcTranslate("ultimateErrorModal.noAvailableCellsFound.buttonText"));
              break;
          case ErrorCodes.SiteIsNotOpenForOrders.toString():
            setButtonText(rcTranslate("ultimateErrorModal.siteIsNotOpenForOrders.buttonText"));
              break;
          default:
            setButtonText(rcTranslate("ultimateErrorModal.confirm"));
          break;
        }
      }
    

      const createCallbackFunctionForCloseButtonPress = (errorCode) => {
        switch (errorCode?.toString()) {
          case ErrorCodes.TRANZILA_GENERIC: 
          case ErrorCodes.TRANZILA_NO_CHARGE_WAS_MADE:
            return history.push("/onboarding/transaction-iframe");
          case ErrorCodes.CellUnavailable.toString():
            return history.push("/onboarding/choose-compound");
          case ErrorCodes.RecaptchaNotValid:
            return history.push("/onboarding/transaction-iframe");
          default:
            return () => {}
        }
      }

      const closePopup = (callbackFunction) => {
        callbackFunction && callbackFunction();
        dispatch(ErrorActions.popError());
      }

  return (
    <MUIDialog
      onClose={() => closePopup(createCallbackFunctionForCloseButtonPress(currentError?.code))}
      aria-labelledby="customized-dialog-title"
      open={shouldShowErrorModal}
      className="dialogComponent"
      sx={{
        "& .MuiDialogTitle-root": {
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          padding: "16px 24px 0px",
          flexDirection: "row-reverse",
          direction: "rtl"
        },
        "& .MuiDialogContent-root": {
          direction: "rtl",
          border: "none !important",
        },
        "& .MuiDialogActions-root": {
          display: "flex",
          justifyContent: "flex-start",
          flexDirection: "row"
        },
      }}
    >
      <DialogTitle sx={{ m: 0, p: 1 }}>
        <IconButton
          aria-label="close"
          onClick={() => closePopup(createCallbackFunctionForCloseButtonPress(currentError?.code))}
          sx={{
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
      </DialogTitle>

      <DialogContent dividers>
        <div className="titleImageWrapper">
          {headerImage}
        </div>
        <div className="titleWrapper">
          <h3>
            {headerTitle}
          </h3>
        </div>
        <div className="subTitleWrapper">
          <p>
            {headerSubTitle}
          </p>
        </div>
      </DialogContent>

      <DialogActions className="dialogActions">

        <Button
          title={buttonText}
          onClick={() => closePopup(createCallbackFunctionForCloseButtonPress(currentError?.code))}
        />

      </DialogActions>
    </MUIDialog>
  );
}

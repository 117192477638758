import React, { useState, useEffect } from "react";
import { Formik } from "formik";
import { useLocation, useHistory } from "react-router-dom";

import { useApi } from "hooks/useApi";
import useTranslations from "hooks/useTranslations";
import Input from "components/Input/Input";
import PageTitle from "components/PageTitle/PageTitle";
import Button from "components/Button/Button";
import LockersGif from "assets/images/lockers-gif.gif";
import { studentDetailsLinkValidation } from "utils/formValidation/studentDetailsLinkValidation";

import "./Migration.scss";
import AutoComplete from "components/AutoComplete/AutoComplete";
import grades from "assets/data/grades";

const StudentDetailsLink = () => {
  const [showSuccessContent, setShowSuccessContent] = useState(false);
  const [showErrorMessage, setShowErrorMessage] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [schoolList, setSchoolList] = useState([]);

  const { executeApi, post, get } = useApi();
  const { rcTranslate, appLanguage } = useTranslations();
  const location = useLocation();
  const history = useHistory();

  const realizationToken = location?.state?.realizationToken;
  const shovarNumber = location?.state?.shovarNumber;

  useEffect(() => {
    if (!realizationToken || !shovarNumber) {
      history.push("/voucher-number");
    }
  }, [realizationToken, shovarNumber, history]);

  // Get all schools from API
  useEffect(() => {
    const getSchoolList = async () => {
      try {
        const res = await executeApi(get("City/GetCitiesSites"));
        if (res?.isSucceeded) {
          if (!res?.content?.cities?.length) return;

          const cities = res?.content?.cities?.filter(
            (city) =>
              !city.nameHE.includes("מיגרציה") ||
              !city.nameEN.includes("Migration")
          );

          let schools = [];

          for (const city of cities) {
            for (const site of city.sites) {
              if (site.isActive) {
                schools.push(site);
              }
            }
          }

          schools = schools.map((school) => ({
            id: appLanguage === "he" ? school.nameHE : school.nameEN,
            label: appLanguage === "he" ? school.nameHE : school.nameEN
          }));

          setSchoolList(schools);
        }
      } catch (err) {
        console.log(err);
      }
    };

    getSchoolList();
  }, [appLanguage, executeApi, get]);

  const initialValues = {
    firstName: "",
    lastName: "",
    phoneNumber: "",
    identificationNumber: "",
    school: "",
    grade: ""
  };

  const getGrades = () => grades.map((i, index) => ({ id: index.toString(), label: i }));

  const getGradeNumbers = () =>
    [...Array(20).keys()].map((i) => ({ id: i + 1, label: (i + 1).toString() }));

  const inputs = [
    {
      id: "firstName",
      type: "string",
      validation: "letters"
    },
    {
      id: "lastName",
      type: "string",
      validation: "letters"
    },
    {
      id: "phoneNumber",
      type: "tel",
      inputProps: { maxLength: 10 },
      validation: "number"
    },
    {
      id: "identificationNumber",
      type: "string",
      inputProps: { maxLength: 9 },
      validation: "number"
    },
    {
      id: "school",
      type: "string",
      fieldType: "autoComplete",
      items: schoolList
    },
    {
      id: "grade",
      fieldType: "select",
      items: getGrades()
    },
    {
      id: "gradeNumber",
      fieldType: "select",
      items: getGradeNumbers()
    }
  ];

  const handleSubmit = async (values, actions) => {
    try {
      const body = {
        firstName: values?.firstName,
        lastName: values?.lastName,
        identificationNumber: values?.identificationNumber?.padStart(9, "0"),
        PhoneNumber: values?.phoneNumber,
        school: values?.school,
        grade: grades[values?.grade] + " " + values?.gradeNumber,
        shovarNumber,
        realizationToken
        // recaptchaKey: "1saf4sdg5dfh1", in future send recaptchaKey
      };
      const res = await executeApi(post("Shovar/RealizeShovar", body));
      if (res?.isSucceeded) {
        setShowSuccessContent(true);
      } else {
        let errorMessage = "",
          result;
        if (res?.message) {
          result = JSON.parse(res?.message);
        }
        if (result?.status && result?.status !== 400) {
          result?.data?.errors?.forEach((element) => {
            errorMessage += " " + element?.message;
          });
        } else {
          errorMessage = res;
        }
        setErrorMessage(errorMessage);
        setShowErrorMessage(true);
      }

      actions.setSubmitting(false);
    } catch (err) {
      actions.setSubmitting(false);
    }
  };

  const studentDetailsContent = () => {
    return (
      <div className="studentDetailsMigration">
        <PageTitle
          title={rcTranslate("studentDetails.title")}
          className="title"
        />

        <div className="subTitle">
          <p>{rcTranslate("migration.studentDetails.subTitle")}</p>
        </div>

        <Formik
          initialValues={initialValues}
          validationSchema={studentDetailsLinkValidation}
          onSubmit={handleSubmit}
          enableReinitialize={true}
        >
          {({
            values,
            errors,
            touched,
            isSubmitting,
            handleSubmit,
            setFieldTouched,
            setFieldValue,
            setFieldError
          }) => {
            const handleFieldTouch = (id) => {
              if (!touched[id]) {
                setFieldTouched(id, true);
              }
            };

            const onChange = (id, value) => {
              if (values[id] === value) {
                return;
              }
              handleFieldTouch(id);
              setFieldValue(id, value);
            };

            const getFieldError = (fieldId) =>
              errors[fieldId] && touched[fieldId] ? errors[fieldId] : null;

            const onSubmit = (e) => {
              e.preventDefault();
              handleSubmit();
            };

            return (
              <form
                className="studentDetailsMigration__form"
                onSubmit={onSubmit}
              >
                {inputs.map(
                  ({
                    id,
                    type,
                    validation,
                    inputProps,
                    items = [],
                    fieldType = "input"
                  }) =>
                    fieldType === "input" ? (
                      <Input
                        key={id}
                        id={id}
                        type={type}
                        label={rcTranslate(
                          `migration.studentDetails.${id}.label`
                        )}
                        placeholder={rcTranslate(
                          `migration.studentDetails.${id}.placeholder`
                        )}
                        value={values[id] || ""}
                        onChange={(e) => onChange(id, e.target.value)}
                        onBlur={() => handleFieldTouch(id)}
                        error={getFieldError(id)}
                        validation={validation}
                        inputProps={inputProps}
                      />
                    ) : (
                      fieldType === "autoComplete" && (
                        <AutoComplete
                          key={id}
                          id={id}
                          label={rcTranslate(
                            `migration.studentDetails.${id}.label`
                          )}
                          placeholder={rcTranslate(
                            `migration.studentDetails.${id}.placeholder`
                          )}
                          items={items}
                          value={values[id] || ""}
                          error={getFieldError(id)}
                          onChange={(value) => onChange(id, value)}
                          multiple={false}
                        />
                      )
                    )
                )}

                <div className="studentDetailsMigration__selectWrapper">
                  {inputs.map(
                    ({ id, items = [], fieldType }) =>
                      fieldType === "select" && (
                        <AutoComplete
                          key={id}
                          id={id}
                          label={rcTranslate(
                            `migration.studentDetails.${id}.label`
                          )}
                          placeholder={rcTranslate(
                            `migration.studentDetails.${id}.placeholder`
                          )}
                          items={items}
                          value={values[id] || ""}
                          error={getFieldError(id)}
                          onChange={(value) => onChange(id, value)}
                          multiple={false}
                        />
                      )
                  )}
                </div>

                {showErrorMessage && (
                  <p className="migration__errorMessage">{errorMessage}</p>
                )}

                <Button
                  type="submit"
                  title={rcTranslate("button.continue")}
                  disabled={
                    isSubmitting ||
                    Object.keys(errors)?.length > 0 ||
                    Object.values(values).find(
                      (i) => !i?.toString()?.length
                    ) !== undefined
                  }
                />
              </form>
            );
          }}
        </Formik>
      </div>
    );
  };

  const successContent = () => {
    return (
      <div className="studentDetailsMigrationSuccess">
        <img
          className="migration__lockersGif"
          src={LockersGif}
          alt="lockers gif"
        />

        <PageTitle
          title={rcTranslate("migration.studentDetails.success.title")}
          className="title"
        />

        <div className="subTitle__success">
          <p>{rcTranslate("migration.studentDetails.success.subTitle")}</p>
        </div>
      </div>
    );
  };

  return showSuccessContent ? successContent() : studentDetailsContent();
};

export default StudentDetailsLink;

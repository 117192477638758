import React, { useEffect } from "react";
import CONSTANTS from "utils/constants";

function DeepLinkNewApp() {
  const getMobileOs = () => {
    const userAgent = window.navigator.userAgent;

    if (/Android/i.test(userAgent)) return 'Android';

    if (/iPad|iPhone|iPods/.test(userAgent)  && !window.MSStream) return 'IOS';

    return 'Unknown';
  };

  useEffect(() => {
    if (getMobileOs() === 'Unknown') return;
    else if (getMobileOs() === 'Android') {
      window.location.href = CONSTANTS.LOCKERIM_MOBILE_CLIENT_APP_STORE_LINKS.GOOGLE_PLAY;
    } else if (getMobileOs() === 'IOS') {
      window.location.href = CONSTANTS.LOCKERIM_MOBILE_CLIENT_APP_STORE_LINKS.APP_STORE;
    }
  }, [getMobileOs]);

  return <div></div>;
}

export default DeepLinkNewApp;
